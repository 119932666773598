.project-lead {
  @media (--sm) {
    padding: var(--header-height-sm) var(--gutter-sm) 0;
  }

  @media (--lg) {
    display: flex;
    min-height: 100vh;
  }
}

.project-lead__text {
  @media (--lg) {
    width: 50%;
    margin-right: calc(var(--gutter-lg) * 0.5);
    padding-left: var(--margin-1);
    padding-top: calc(var(--header-height) + var(--gutter-lg) + var(--margin-2));
  }
}

.project-lead__image {
  @media (--sm) {
    margin-top: var(--margin-2-sm);

    img {
      display: block;
    }
  }

  @media (--lg) {
    position: relative;
    width: 50%;
    margin-left: calc(var(--gutter-lg) * 0.5);
  }
}

.project-lead__image-fig  {
  @media (--lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}