.team-members {
  @media (--sm) {
    li + li {
      margin-top: 1em;
    }
  }
}

.team-members__tenure {
  @media (--sm) {
    display: block;
  }
}