.news-hero {
  display: block;
  width: 100%;
  height: auto;

  @media (--lg) {
    width: auto;
    height: 573px;
  }
}

.news-archive-title {
  margin: var(--margin-2-sm) 0 var(--margin-1-sm);

  @media (--lg) {
    margin: var(--margin-2) 0;
  }
}