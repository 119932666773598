.contact-group:not(:last-child) {
  margin-bottom: var(--margin-2-sm);

  @media (--lg) {
    margin-bottom: var(--margin-2);
  }
}

.contact-group {
  word-break: break-all;

  a {
    transition: color 350ms;
    &:hover {
      color: var(--color-pale-rust);
    }
  }
}

.contact-group__secondary {
  display: block;

  @media (--sm) {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}

.contact-group__secondary-label {
  @media (--sm) {
    display: block;
  }
}

.map {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: relative;
}

.map__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-wrapper {
  @media (--sm) {
    margin-top: var(--margin-3-sm)
  }
}