.home-intro {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-rust);

  img {
    opacity: 0.8;
  }
}

.home-intro__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: var(--gutter-sm) var(--margin-1-sm);

  @media (--lg) {
    padding: var(--gutter-lg) var(--margin-1); 
  }
}

.home-intro__text {
  max-width: 37em;
  width: 100%;
  opacity: 0;
}

.home-intro__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: var(--gutter-sm) var(--margin-1-sm);
  opacity: 0;

  svg {
    display: block;
    height: 50px;

    &:nth-child(2) {
      margin-left: -2%;
    }
  }

  @media (--lg) {
    padding: var(--gutter-lg) var(--margin-1);

    svg {
      height: 80px;
    }
  }
}