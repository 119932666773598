.site-footer {
  margin-top: var(--margin-5-sm);
  background-color: var(--color-rust);
  color: #fff;

  @media (--lg) {
    display: flex;
    min-height: 480px;
    margin-top: var(--margin-5);
  }
}

.site-footer__cta,
.site-footer__details {
  width: 100%;

  @media (--lg) {
    width: 50%;
  }
}

.site-footer__cta {
  position: relative;

  @media (--sm) {
    height: 250px;
    display: block;
  }

  img {
    opacity: 0.75;
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }
}

.site-footer__cta {
  @media (hover: hover) {
    &:hover {
      .site-footer__cta-overlay {
        transform: translateY(-10px);
      }
    }
  }
}

.site-footer__cta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: transform 500ms var(--ease), -webkit-transform 500ms var(--ease);

  @media (--sm) {
    padding: var(--margin-1-sm);
  }

  @media (--lg) {
    padding: var(--margin-1);
    padding-top: calc(var(--margin-1) + 5px);
  }
}

.site-footer__details {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (--sm) {
    padding: var(--margin-1-sm);
  }

  @media (--lg) {
    padding: var(--margin-1);
  }

  button, a {
    transition: color 350ms;
    &:hover {
      color: var(--color-pale-rust);
    }
  }
}

.site-footer__btt {
  display: inline-flex;
  align-items: center;

  .icon svg {
    display: block;
    width: 10px;
    height: 6px;
  }

  .icon {
    margin-right: 5px;
  }
}

.site-footer__text {
  p + p {
    margin-top: 1em;
  }

  strong, 
  b {
    font-weight: normal;
    color: var(--color-pale-rust);
  }
}