.t-color-pale-rust {
  color: var(--color-pale-rust);
}

.t-color-rust {
  color: var(--color-rust);
}

.t-color-white {
  color: #fff;
}