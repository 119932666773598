.project-details {
  margin-top: var(--margin-2-sm);
  margin-bottom: var(--margin-2-sm);

  @media (--lg) {
    margin-top: var(--margin-2);
    margin-bottom: var(--margin-2);
  }
}

.project-details__column {
  @media (--sm) {
    margin-top: var(--margin-1-sm);
  }
}