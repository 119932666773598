.site-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  color: #fff;
  background-color: var(--color-rust);
  padding: calc(var(--header-height-sm) + 8px) 0 var(--gutter-sm);
  height: 100%;
  transition: all 750ms;
  overflow-y: auto;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;

  a, button {
    transition: color 350ms;

    &.is-active,
    &:hover {
      color: var(--color-pale-rust);
    }
  }

  @media (--sm) {
    opacity: 0;

    .is-nav-active & {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media (--lg) {
    padding: var(--gutter-lg) 0;
    height: 20.375rem;
    transform: translateY(-100%);

    .is-nav-active & {
      transform: translateY(0);
      pointer-events: all;
    }
  }
}

.site-nav-shroud {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  z-index: 89;
  transition: all 500ms;
  background-color: var(--color-rust);

  .is-nav-active & {
    opacity: 0.5;
    pointer-events: all;
  }
}

.site-nav__primary {
  @media (--sm) {
    font-size: var(--font-size-h2-sm);
    line-height: var(--font-lh-h2-sm);
  }
}

.site-nav__secondary {
  @media (--sm) {
    margin-top: var(--margin-1-sm);
    font-size: var(--font-size-body-sm);
    line-height: var(--font-lh-body-sm);
    font-weight: 300;
  }
}