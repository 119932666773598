*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-anchor: none;
}

.is-locked {
  overflow-y: hidden;
}

.site {
  flex: 1 0 auto;
}

.preload * {
  transition: none !important;
}

.container {
  @media (--sm) {
    padding: 0 var(--margin-1-sm);
  }

  @media (--lg) {
    padding: 0 var(--margin-1);
  }
}