.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  transition: transform 500ms;

  svg {
    fill: #fff;
    transition: fill 500ms;
  }

  html:not(.is-nav-active):not(.is-lightbox-active) [data-scroll-dir="down"] & {
    transform: translateY(-100%);
  }

  .is-scrolled:not(.is-nav-active) &,
  html:not(.is-nav-active) body:not(.has-header-background) & {
    svg {
      fill: var(--color-rust);
    }
  }
}

.site-header__wrap {
  display: flex;
  justify-content: space-between;
  padding: var(--gutter-sm) 0;

  @media (--lg) {
    padding: var(--gutter-lg) 0;
  }
}

.site-icon {
  pointer-events: all;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (--sm) {
    width: 38px;
    height: 60px;
    padding: 10px 10px 0;
    margin: -10px 0 0 -10px;
  }

  @media (--lg) {
    width: 28px;
    height: 81px;
  }
}

.nav-button {
  position: relative;
  pointer-events: all;

  & > * {
    position: absolute;
    transition: opacity 350ms;
  }

  @media (--sm) {
    margin: -10px -10px 0 0;
    width: 41px;
    height: 39px;
    padding: 10px;

    & > * {
      width: 21px;
      height: 19px;
      top: 10px;
      left: 10px;
    }
  }

  @media (--lg) {
    width: 30px;
    height: 26px;

    & > * {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.when-active {
  opacity: 0;
  pointer-events: none;

  @media (--sm) {
    svg {
      width: 19px;
      height: 19px;
      display: block;
      margin-left: 1px;
    }
  }
}

.when-inactive {
  transition-delay: 350ms;
}

.is-nav-active {
  .when-active {
    opacity: 1;
    pointer-events: all;
    transition-delay: 350ms;
  }

  .when-inactive {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0ms;
  }
}