.profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--color-rust-rgb), 0);
  pointer-events: none;
  transition: all 500ms;

  &.is-active {
    background-color: rgba(var(--color-rust-rgb), 0.5);
    pointer-events: all;

    .profile-overlay__panel {
      transform: translateX(0);
    }
  }
}

.profile-overlay__panel {
  width: calc(100% - 65px);
  height: 100%;
  background-color: #fff;
  margin-left: auto;
  padding: var(--header-height-sm) var(--margin-1-sm) var(--margin-1-sm);
  overflow-y: scroll;
  transform: translateX(100%);
  transition: all 500ms;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;

  @media (--lg) {
    width: 50%;
    padding: 100px var(--margin-1) var(--margin-1);
  }
}

.profile-overlay__close {
  position: fixed;
  display: block;
  margin-left: auto;

  svg {
    fill: var(--color-rust);
  }

  @media (--sm) {
    top: var(--margin-1-sm);
    right: var(--margin-1-sm);
    width: 39px;
    height: 39px;
    padding: 10px;
    margin: -10px -10px 0 auto;
  }

  @media (--lg) {
    top: var(--margin-1);
    right: var(--margin-1);
    display: block;
    width: 30px;
    height: 26px;
  }
}

.profile-overlay__details {
  margin: var(--margin-2-sm) 0 0;
  
  @media (--lg) {
    margin: var(--margin-1) 0 0;
  }

  ul {
    li {
      padding-left: 2em;
      text-indent: -2em;
    }
  }
}

.profile-overlay__text {
  margin-top: var(--margin-3-sm);

  @media (--lg) {
    margin-top: 100px;
  }
}

.profile-overlay__details {
  li + li {
    margin-top: 0.25em;
  }

  a {
    border-bottom: 1px solid;
    transition: color 500ms;
  }

  @media (hover: hover) {
    a:hover {
      color: var(--color-pale-rust);
    }
  }
}