.table-tools {
  display: flex;
  
  @media (--sm) {
    font-weight: 400;
    font-size: var(--font-size-small-sm);
    line-height: var(--font-lh-small-sm);
    margin-bottom: var(--margin-1-sm);
  }

  @media (--lg) {
    margin-bottom: var(--margin-2);
  }
}

.table-tools__label {
  margin-right: 0.5em;
}

.table-tools__single {
  display: inline-flex;
  color: var(--color-pale-rust);
  transition: color 350ms;

  &.is-active,
  &:hover {
    color: var(--color-rust);

    svg {
      fill: var(--color-rust);
    }
  }

  &[data-sort-order="asc"] .icon {
    svg {
      transform: rotate(180deg);
    }
  }

  & + .table-tools__single {
    margin-left: 0.5em;
  }

  svg {
    width: 11px;
    height: 11px;
    fill: var(--color-pale-rust);
    transition: fill 350ms;

    @media (--lg) {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }

  .icon {
    margin-left: 0.25em;
  }
}