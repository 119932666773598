.grid {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--gutter-slight-sm) * -0.5);

  @media (--lg) {
    margin: calc(var(--gutter-slight-lg) * -0.5);
  }
}

.grid-thumb {
  width: 50%;
  padding: calc(var(--gutter-slight-sm) * 0.5);

  @media (width >= #{$size-xs}) and (width < #{$size-md}) {
    width: 33.33%;
  }

  @media (--lg) {
    width: 25%;
    padding: calc(var(--gutter-slight-lg) * 0.5);
  }
}

.grid-thumb__link {
  display: block;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  transition: background-color 500ms;
  width: 100%;

  &:hover {
    background-color: var(--color-rust);

    .grid-thumb__img {
      opacity: 0.5;
      filter: grayscale(1);
    }

    .grid-thumb__overlay {
      transform: translateY(-10px);
    }
  }
}

.grid-thumb__img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 750ms 0ms;
  
  backface-visibility: hidden;
  mix-blend-mode: multiply;
}

.grid-thumb__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: #fff;
  padding: var(--margin-1);
  text-align: center;
  transition: transform 500ms var(--ease), -webkit-transform 500ms var(--ease);
}
