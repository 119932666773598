.lazyload,
.lazyloading {
  opacity: 0;

  &.lazyload--static {
    opacity: 1;
  }
}

.lazyload--fade {
  transition: opacity 500ms 500ms;
  backface-visibility: hidden;
}