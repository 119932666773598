@font-face {
  font-family: "Fakt";
  font-weight: 300;
  src: url("/assets/fonts/Fakt-Blond.woff2") format("woff2"),
       url("/assets/fonts/Fakt-Blond.woff") format("woff");
}

@font-face {
  font-family: "Fakt";
  font-weight: 300;
  font-style: italic;
  src: url("/assets/fonts/Fakt-BlondItalic.woff2") format("woff2"),
       url("/assets/fonts/Fakt-BlondItalic.woff") format("woff");
}

@font-face {
  font-family: "Fakt";
  font-weight: 400;
  src: url("/assets/fonts/Fakt-Normal.woff2") format("woff2"),
       url("/assets/fonts/Fakt-Normal.woff") format("woff");
}

@font-face {
  font-family: "Fakt";
  font-weight: 400;
  font-style: italic;
  src: url("/assets/fonts/Fakt-NormalItalic.woff2") format("woff2"),
       url("/assets/fonts/Fakt-NormalItalic.woff") format("woff");
}

@font-face {
  font-family: "Fakt";
  font-weight: 700;
  src: url("/assets/fonts/Fakt-Medium.woff2") format("woff2"),
       url("/assets/fonts/Fakt-Medium.woff") format("woff");
}

@font-face {
  font-family: "Fakt";
  font-weight: 700;
  font-style: italic;
  src: url("/assets/fonts/Fakt-MediumItalic.woff2") format("woff2"),
       url("/assets/fonts/Fakt-MediumItalic.woff") format("woff");
}