body {
  font-family: var(--font-sans-stack);
  color: var(--color-rust);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

.t-wrap {
  overflow: hidden;
}

::selection {
  background-color: rgba(var(--color-highlight), 0.99);
}

.t-baseline {
  padding: 0.05px 0;

  &::before { 
    content: "";  
    margin-top: -0.135em;  
    display: block; 
    height: 0;  
  }

  &::after {  
    content: "";  
    margin-bottom: -0.135em; 
    display: block; 
    height: 0;  
  }
}

.t-baseline-t {
  padding-top: 0.05px;

  &::before { 
    content: "";  
    margin-top: -0.135em;  
    display: block; 
    height: 0;  
  }
}

.t-baseline-b {
  padding-bottom: 0.05px;

  &::after { 
    content: "";  
    margin-bottom: -0.135em;  
    display: block; 
    height: 0;  
  }
}

.t-baseline-small {
  padding: 0.05px 0;

  &::before { 
    content: "";  
    margin-top: -0.3em;  
    display: block; 
    height: 0;  
  }

  &::after {  
    content: "";  
    margin-bottom: -0.3em; 
    display: block; 
    height: 0;  
  }
}

.t-baseline-small-t {
  padding-top: 0.05px;

  &::before { 
    content: "";  
    margin-top: -0.3em;  
    display: block; 
    height: 0;  
  }
}

.t-baseline-small-b {
  padding-bottom: 0.05px;

  &::after { 
    content: "";  
    margin-bottom: -0.3em;  
    display: block; 
    height: 0;  
  }
}

.t-h1 {
  font-weight: 300;
  letter-spacing: -0.008714285714em;

  @media (--lg) {
    font-size: var(--font-size-h1-lg);
    line-height: var(--font-lh-h1-lg);

    &.m-b-lh {
      margin-bottom: var(--font-lh-h1-lg);
    }
  }

  @media (--sm) {
    font-size: var(--font-size-h1-sm);
    line-height: var(--font-lh-h1-sm);

    &.m-b-lh {
      margin-bottom: var(--font-lh-h1-sm);
    }
  }
}

.t-h2  {
  font-weight: 300;
  letter-spacing: -0.0086em;

  @media (--lg) {
    font-size: var(--font-size-h2-lg);
    line-height: var(--font-lh-h2-lg);

    &.m-b-lh {
      margin-bottom: var(--font-lh-h2-lg);
    }
  }

  @media (--sm) {
    font-size: var(--font-size-h2-sm);
    line-height: var(--font-lh-h2-sm);

    &.m-b-lh {
      margin-bottom: var(--font-lh-h2-sm);
    }
  }
}

.t-body {
  font-weight: 300;
  
  @media (--lg) {
    font-size: var(--font-size-body-lg);
    line-height: var(--font-lh-body-lg);

    &.m-b-lh {
      margin-bottom: var(--font-lh-body-lg);
    }
  }
  @media (--sm) {
    font-size: var(--font-size-body-sm);
    line-height: var(--font-lh-body-sm);

    &.m-b-lh {
      margin-bottom: var(--font-lh-body-sm);
    }
  }
}

.t-small {
  @media (--lg) {
    font-size: var(--font-size-small-lg);
    line-height: var(--font-lh-small-lg);

    &.m-b-lh {
      margin-bottom: var(--font-lh-small-lg);
    }
  }
  @media (--sm) {
    font-size: var(--font-size-small-sm);
    line-height: var(--font-lh-small-sm);

    &.m-b-lh {
      margin-bottom: var(--font-lh-small-sm);
    }
  }
}

.text-content {
  @extend .t-body;

  p + p {
    text-indent: 2em;
  }

  h2 {
    font-weight: 700;
  }

  ul,
  h2 {
    margin-bottom: var(--font-lh-body-lg);
  }

  li {
    padding-left: 1em;
    position: relative;

    &:before {
      content: '—';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.text-content--breaks {
  @extend .t-body;

  p + p {
    text-indent: 0;
    margin-top: var(--font-lh-body-lg);
  }
}

.t-uc {
  text-transform: uppercase;
}

.t-strong {
  font-weight: 700;
}