.img-block {
  display: block;
  width: 100%;
  height: auto;
}

.img-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-with-caption {
  position: relative;

  img {
    position: relative;
    z-index: 1;
  }
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  color: #fff;
  padding: var(--margin-1-sm);

  @media (--lg) {
    padding: var(--margin-1);
  }
}