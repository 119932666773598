.page-intro {
  position: relative;
  height: 100vh;
  height: calc(100vh - var(--ios-gap));
  color: #fff;
}

.page-intro__slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-intro,
.page-intro__slide {
  background-color: var(--color-dark-rust);

  img {
    opacity: 0.8;
  }
}

.page-intro__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-intro__slide-text {
  @media (--sm) {
    padding: 0 46px;
  }

  @media (--lg) {
    display: flex;
    justify-content: space-between;
    height: var(--font-lh-h2-lg);
  }
}

/* .page-intro__slide-counter {
  margin-top: 0.5em;

  @media (--lg) {
    display: none;
  }
} */

.page-intro__proceed {
  position: absolute;
  bottom: var(--margin-1-sm);
  left: 0;
  width: 100%;
  z-index: 10;
  
  @media (--lg) {
    bottom: var(--margin-1);
  }
}

.page-intro__return {
  position: absolute;
  top: calc(var(--header-height-sm) + 1em);
  left: 0;
  width: 100%;

  @media (--lg) {
    top: calc(var(--header-height) - 1em);
  }
}

.proceed {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
}

.proceed-icon {
  display: block;
  width: 29px;
  height: 29px;
  margin-right: 17px;

  @media (--lg) {
    width: 36px;
    height: 38px;
    margin-right: 10px;
  }

  svg {
    fill: #fff;
  }
}

.page-intro__slideshow {
  height: 100%;
}

.page-intro__button {
  position: absolute;
  top: 0;
  height: 100%;
  padding: 0 var(--gutter-sm);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--lg) {
    padding: 0 var(--gutter-lg);
  }

  svg {
    width: 29px;
    height: 29px;
    fill: #fff;

    @media (--lg) {
      width: 38px;
      height: 36px;
    }
  }
}

.page-intro__button--prev {
  left: 0;
}

.page-intro__button--next {
  right: 0;
}

.return-link {
  display: flex;
  align-items: center;

  .icon svg {
    display: block;
    width: 6px;
    height: 10px;
  }

  .icon {
    margin-right: 10px;
  }
}

.page-intro__counter {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 10;
  transform: translateY(-50%);
  pointer-events: none;

  @media (--sm) {
    display: none;
  }
}

.page-intro__counter-inner {
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0.05769230769em;
}
