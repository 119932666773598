.page-section {
  margin: var(--margin-3-sm) 0;

  @media (--lg) {
    margin: var(--margin-3) 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.page-section__image {
  @media (--sm) {
    margin-top: var(--margin-2-sm);
  }
}