.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 150;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms;
  display: none;

  .site-header {
    position: absolute;

    svg {
      fill: var(--color-rust);
    }
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    display: block;
  }

  .swiper-container {
    height: 100%;
  }
}

.lightbox__slide {
  @media (--sm) and (orientation: portrait) {
    padding: var(--header-height-sm) var(--margin-1-sm);
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.lightbox__counter {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: var(--margin-1-sm);
  letter-spacing: 0.05769230769em;

  @media (--lg) {
    padding: var(--margin-1);
  }
}

.page-lightbox__button {
  width: 38px;
  height: 36px;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);

  @media (--sm) {
    display: none;
  }

  svg {
    fill: var(--color-rust);
  }
}

.page-lightbox__button--prev {
  left: var(--gutter-lg);
}

.page-lightbox__button--next {
  right: var(--gutter-lg);
}