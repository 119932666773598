:root {
  --ios-gap: 0.01px;
  --font-sans-stack: "Fakt", "Helvetica", "Arial", sans-serif;
}

@custom-media --sm (width < #{$size-md});
@custom-media --lg (width >= #{$size-md});

:root {
  --color-rust: #D07D39;
  --color-rust-rgb: 208, 125, 57;
  --color-pale-rust: #E7BE9C;
  --color-dark-rust: #65360F;
  --color-highlight: 240, 223, 209;

  --ease: cubic-bezier(.4,0,.2,1);
}

:root {
  --font-size-h1-lg: 70px;
  --font-size-h1-sm: 47px;

  --font-lh-h1-lg: 65px;
  --font-lh-h1-sm: 47px;

  --font-size-h2-lg: 50px;
  --font-size-h2-sm: 35px;

  --font-lh-h2-lg: 52px;
  --font-lh-h2-sm: 37px;

  --font-size-body-lg: 25px;
  --font-size-body-sm: 20px;

  --font-lh-body-lg: 28px;
  --font-lh-body-sm: 25px;

  --font-size-small-lg: 16px;
  --font-size-small-sm: 14px;

  --font-lh-small-lg: 21px;
  --font-lh-small-sm: 19px;
}

:root {
  --margin-5: 290px;
  --margin-4: 166px;
  --margin-3: 85px;
  --margin-2: 44px;
  --margin-1: 26px;

  --margin-5-sm: 200px;
  --margin-4-sm: 120px;
  --margin-3-sm: 64px;
  --margin-2-sm: 33px;
  --margin-1-sm: 17px;

  --gutter-lg: 20px;
  --gutter-sm: 17px;

  --gutter-slight-lg: 7px;
  --gutter-slight-sm: 7px;

  --header-height: 105px;
  --header-height-sm: 84px;
}