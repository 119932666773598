.category-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.25em;

  a {
    margin: 0.25em;
  }
}

.comma-category-list,
.category-list {
  a,
  .comma {
    transition: color 350ms;
    color: var(--color-pale-rust);

    &.is-active,
    &:hover {
      color: var(--color-rust);
    }
  }
}


.category-list-wrap {
  margin-bottom: var(--margin-1-sm);

  @media (--lg) {
    margin-bottom: var(--margin-1);
  }
}