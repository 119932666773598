.slideshow__container {
  position: relative;
  padding: 0 var(--margin-1-sm);

  @media (--lg) {
    padding: 0 var(--margin-1);
  }
}

.slideshow__slide {
  width: auto;
  margin-right: var(--gutter-slight-sm);
  cursor: url(../img/icon-zoom.png) 16 16,zoom-in!important;
  cursor: url(../img/icon-zoom.svg) 16 16,zoom-in!important;
  cursor: -webkit-image-set(url(../img/icon-zoom.png) 1x,url(../img/icon-zoom@2x.png) 2x) 16 16,zoom-in!important;

  @media (--lg) {
    margin-right: var(--gutter-slight-lg);
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    display: block;
    height: 200px;
    width: auto;
    object-fit: cover;

    @media (--sm) {
      max-width: calc(100vw - (var(--margin-1-sm) * 2));
    }

    @media (width >= #{$size-xs}) and (width < #{$size-sm}) {
      height: 300px;
    }

    @media (width >= #{$size-sm}) and (width < #{$size-md}) {
      height: 400px;
    }

    @media (--lg) {
      height: 573px;
    }
  }
}

.slideshow__text {
  margin-bottom: var(--margin-2-sm);

  @media (--lg) {
    margin-bottom: var(--margin-2);
  }
}

.slideshow__slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: #fff;
  padding: var(--margin-1-sm);
  text-align: center;

  @media (--lg) {
    padding: var(--margin-1);
  }
  
  & > * {
    transition: transform 500ms var(--ease), -webkit-transform 500ms var(--ease);
  }

  &:hover {
    & > * {
      transform: translateY(-10px);
    }
  }
}

.slideshow__thumb {
  background-color: var(--color-rust);

  img {
    opacity: 0.5;
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }
}

.slideshow__button {
  width: 38px;
  height: 36px;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  transition: opacity 500ms;

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  svg {
    fill: #fff;
  }

  @media (--sm) {
    display: none;
  }
}

.slideshow__button--prev {
  left: calc(var(--margin-1) + var(--gutter-slight-lg));
}

.slideshow__button--next {
  right: calc(var(--margin-1) + var(--gutter-slight-lg));
}