
.responsive-embed {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.embed-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.is-playing {
    .embed__cover {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.embed__inner,
.embed__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed__cover {
  z-index: 2;
  background-color: #fff;
  transition: opacity 500ms;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.embed__cover-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.embed__label {
  display: inline-flex;
  align-items: center;

  .icon {
    width: 29px;
    height: 29px;
    margin-right: 10px;

    @media (--lg) {
      width: 36px;
      height: 38px;
    }
  }

  svg {
    fill: #fff;
  }
}