.page-lead {
  margin-top: var(--header-height-sm);
  margin-bottom: var(--margin-2-sm);

  @media (--lg) {
    margin-top: var(--header-height);
    margin-bottom: var(--margin-4);
  }
}

.page-lead__marker {
  @media (--sm) {
    padding-top: 1em;
    margin-bottom: var(--gutter-sm);
  }

  @media (--lg) {
    margin-top: -1em;
    margin-bottom: var(--gutter-lg);
  }
}