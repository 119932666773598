.awards-table {
  overflow: hidden;
}

.awards-table-wrapper {
  @media (--sm) {
    margin-top: var(--margin-3-sm)
  }
}

.awards-table__award {
  @media (--lg) {
    display: flex;  
    margin-right: calc(var(--gutter-lg) * -0.5);
    margin-left: calc(var(--gutter-lg) * -0.5);

    & > * {
      padding-right: calc(var(--gutter-lg) * 0.5);
      padding-left: calc(var(--gutter-lg) * 0.5);
    }
  }
}

.award-title {
  @media (--lg) {
    width: 77.77777778%;
  }
}

.award-year {
  @media (--lg) {
    width: 22.22222222%;
  }
}

.awards-table__header {
  @media (--sm) {
    display: none;
  }
}

.awards-table__row {
  padding: var(--margin-1-sm) 0 var(--margin-2-sm);
  border-bottom: 1px solid;

  &.awards-table__row--collapsed {
    padding: var(--margin-1) 0;

    .awards-table__project-link {
      display: none;
    }
  }

  @media (--sm) {
    &:first-child {
      border-top: 1px solid;
    }
  }

  @media (--lg) {
    padding: var(--margin-1) 0 var(--margin-3);

    &:first-child {
      padding-top: 0;
    }
  }
}

.awards-table__awards {
  line-height: 1.52;

  @media (--sm) {
    font-weight: 400;
    font-size: var(--font-size-small-sm);
    line-height: var(--font-lh-small-sm);
    margin-top: 1em;
  }
}

.awards-table__award {
  @media (--sm) {
    display: block;

    & + .awards-table__award {
      margin-top: 5px;
    }
  }
}