.ragged-grid {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--gutter-slight-sm) * -0.5);

  @media (--lg) {
    margin: calc(var(--gutter-slight-lg) * -0.5);
  }
}

.ragged-thumb {
  padding: calc(var(--gutter-slight-sm) * 0.5);

  @media (--lg) {
    padding: calc(var(--gutter-slight-lg) * 0.5);
  }
}

.ragged-thumb__link {
  display: block;
  position: relative;
  background-color: transparent;
  transition: background-color 750ms 0ms;

  .ragged-grid--overlay &,
  &:hover {
    background-color: var(--color-rust);

    .ragged-thumb__img {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
  
  &:hover {
    .ragged-thumb__overlay {
      transform: translateY(-10px);
    }
  }
}

.ragged-thumb__img {
  display: block;
  width: auto;
  max-width: 100%;
  transition: all 750ms 0ms;
  object-fit: cover;
  backface-visibility: hidden;
  mix-blend-mode: multiply;

  @media (--lg) {
    height: 420px;
  }

  @media (--sm) {
    height: 200px;
  }
}

.ragged-thumb__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: #fff;
  padding: var(--margin-1-sm);
  text-align: center;
  transition: transform 500ms var(--ease), -webkit-transform 500ms var(--ease);

  @media (--lg) {
    padding: var(--margin-1);
  }
}
