.subscribe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: var(--color-rust);
  color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms;

  .is-subscribe-active & {
    opacity: 1;
    pointer-events: all;
  }
}

.subscribe-overlay__icon {
  position: absolute;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #fff;
  }

  @media (--sm) {
    top: var(--gutter-sm);
    left: var(--gutter-sm);
    width: 38px;
    height: 60px;
    padding: 10px 10px 0;
    margin: -10px 0 0 -10px;
  }

  @media (--lg) {
    width: 28px;
    height: 81px;
    top: var(--gutter-lg);
    left: var(--margin-1);
  }
}

.subscribe-overlay__close {
  position: absolute;
  
  svg {
    fill: #fff;
  }

  @media (--sm) {
    top: var(--gutter-sm);
    right: var(--gutter-sm);
    width: 41px;
    height: 39px;
    padding: 10px 11px;
    margin: -10px -10px 0 0;
  }

  @media (--lg) {
    top: var(--gutter-lg);
    right: var(--margin-1);
    width: 30px;
    height: 26px;
  }
}

.subscribe-form {
  display: flex;
  margin-top: 1em;
  padding-bottom: 0.25em;

  input {
    flex: 1 0 auto;
  }

  button {
    transition: color 350ms;
    color: var(--color-pale-rust);

    &:hover {
      color: #fff;
    }
  }

  @media (--sm) {
    flex-direction: column;

    input {
      border-bottom: 1px solid;

      &::placeholder {
        color: var(--color-pale-rust);
        opacity: 1;
      }
    }

    button {
      margin-top: 1em;
    }
  }

  @media (--lg) {
    border-bottom: 1px solid;

    input {
      &::placeholder {
        color: #fff;
        opacity: 1;
      }
    }
  }
}